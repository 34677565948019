body {
  overflow: hidden;
  background-color: black !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Titan One", serif;

}

.logo {
  height: 130px;
  width: auto;
  top: 4vh;
  left: 2%;
  z-index: 11;
  position: absolute;

}

.main-input {
  min-width: 600px;
  margin: 20px auto;
  padding: 20px;
  font-family: "Kode Mono", monospace;
}

.header {
  text-align: center;
  color: #fff;
  font-family: "Kode Mono", monospace;
  font-size: 30px;
  font-weight: 400;

}

.background-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.img-coming {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.parent {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: end;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10000;
}

.sub {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 4vw;
}

.json-icon {
  width: 20vw;
  /* margin-top: 20vh; */
  height: auto;
}

.coming-soon {
  height: 3vw;
  margin-top: 4vh;
  width: auto;
}

.ape-chain {
  height: 2.5vw;
  margin-top: 20vh;
  width: auto;
}

.message {
  padding-top: 0;
  margin-top: 0;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;


  z-index: 100;
}

@media only screen and (max-width: 768px) {
  .parent {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 10000;
  }

  .sub {}

  .json-icon {
    height: auto;
    width: 90vw;
  }

  .coming-soon {
    height: auto;
    width: 50vw;
    margin-top: 1vh;
  }

  .ape-chain {
    height: auto;
    width: 30vw;
    margin-top: 20vh;
  }

  .main-input {
    min-width: auto;
  }

  .message {
    position: absolute;
    z-index: 100;
    font-size: "14px" !important;
    font-weight: "200",

  }
}